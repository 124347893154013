import { AttachmentType } from "infra/api/contracts";
import arxs from "infra/arxs";
import WizardController from 'modules/WizardController';

class AssetWizard extends WizardController {
    onLookupsChange(stateProxy) {
        this.getContext(stateProxy);
    }

    copyRecommendationData = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const metadata = stateProxy.getter("metadata");

            const getValue = this.buildGetValue(stateProxy);
            let attachmentInfo = getValue("attachmentInfo");
            if (!attachmentInfo || Object.keys(attachmentInfo).length === 0) {
                attachmentInfo = { attachments: [], storedFiles: [], documents: [] };
            }
            const ref = getValue("recommendation");

            if (ref) {
                const subject = arxs.Api.lookups.resolveSubject(ref);
                if (subject) {

                    let storedFilesToAdd = [];
                    let documentsToAdd = [];

                    for (const allowedDocType of metadata.allowedDocumentTypes) {
                        const attachmentPerType = subject.attachmentInfo.attachments.filter(x => x.type === allowedDocType)[0];

                        if (attachmentPerType) {
                            if (!attachmentInfo.attachments.some(x => x.type === attachmentPerType.type)) {
                                attachmentInfo.attachments.push({ type: attachmentPerType.type, value: [] });
                            }

                            for (const attValue of attachmentPerType.value.filter(x => !x.isDeleted)) {
                                let newAttPerType = attachmentInfo.attachments.filter(x => x.type === attachmentPerType.type)[0];
                                const newId = arxs.uuid.generate();
                                newAttPerType.value.push({ ...attValue, id: newId });

                                switch (attValue.type) {
                                    case AttachmentType.StoredFile: const storedFileToAdd = { ...subject.attachmentInfo.storedFiles.filter(x => x.id === attValue.id)[0], id: newId };
                                        storedFilesToAdd.push(storedFileToAdd);
                                        break;
                                    case AttachmentType.Document: const documentToAdd = { ...subject.attachmentInfo.documents.filter(x => x.id === attValue.id)[0], id: newId };
                                        documentsToAdd.push(documentToAdd); break;
                                    default: break;
                                }
                            }
                        }
                    }

                    attachmentInfo.storedFiles.push(...storedFilesToAdd)
                    attachmentInfo.documents.push(...documentsToAdd)

                    let newData = {
                        ...data,
                        attachmentInfo: attachmentInfo
                    };

                    stateProxy.setter({ data: newData }, resolve);
                }
            } else {
                resolve();
            }
        });
    };

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        const expirationDate = getCurrentFieldValue("expirationDate");
        const notificationDate = getCurrentFieldValue("expirationNotificationDate");
        const responsible = getCurrentFieldValue("responsible");
        const hazardSentences = getCurrentFieldValue("hazardSentences");
        const preventionSentences = getCurrentFieldValue("preventionSentences");
        const legalStructure = getCurrentFieldValue("legalStructure");
        const branch = getCurrentFieldValue("branch");

        debugger;
        if (!branch && !legalStructure){
            preValidation["context"] = {error: arxs.t("wizard.validation.field_is_required", {field: arxs.t(`field.context`)})};
        }


        if (expirationDate) {
            if (!notificationDate) {
                preValidation["expirationNotificationDate"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.expirationNotificationDate`) }) };
            }
            if (!responsible) {
                preValidation["resposnible"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t("field.responsible") }) };
            }
        }

        if (notificationDate) {
            if (!expirationDate) {
                preValidation["expirationDate"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t("field.expirationDate") }) };
            }
            if (!responsible) {
                preValidation["resposnible"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t("field.responsible") }) };
            }
        }

        if (notificationDate && expirationDate) {
            if (!this.isExpirationDateAfterNotificationDate(expirationDate, notificationDate)) {
                preValidation["expirationDate"] = { error: arxs.t("wizard.validation.out_of_range_greater", { field1: arxs.t("field.expirationDate"), field2: arxs.t("field.expirationNotificationDate") }) };
            }
        }

        if (hazardSentences){
            const sentenceIds = (hazardSentences || []).map(x => x.id);
            if (sentenceIds.length !== sentenceIds.distinct(x => x).length){
                preValidation["hazardSentences"] = {error: arxs.t("wizard.validation.list_duplicate_value",  {field: arxs.t("field.hazardSentences")})};
            }
        }

        if (preventionSentences){
            const sentenceIds = (preventionSentences || []).map(x => x.id);
            if (sentenceIds.length !== sentenceIds.distinct(x => x).length){
                preValidation["preventionSentences"] = {error: arxs.t("wizard.validation.list_duplicate_value",  {field: arxs.t("field.preventionSentences")})};
            }
        }

        return preValidation;
    }

    isExpirationDateAfterNotificationDate = (expirationDate, notificationDate) => {
        return new Date(expirationDate).getTime() > new Date(notificationDate).getTime();
    }

}
export default AssetWizard;
