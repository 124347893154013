import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import PeriodicalWizard from "./PeriodicalWizard";
import PeriodicalActions from "./PeriodicalActions";
import PeriodicalBoard from "./PeriodicalBoard";
import { OriginModuleEnum, RelationshipType, ObjectDocumentType } from "infra/api/contracts";
import { PeriodicalStatus } from "infra/api/contracts/PeriodicalStatus";
import PeriodicalCard from "./PeriodicalCard";

const getPeriodicalMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Periodical;

  return {
    module,
    title: arxs.t("modules.periodical"),
    icon: "fas fa-repeat",
    path: "/periodical",
    base: {
      name: "Periodical",
      route: "/periodical",
      getResource: () => arxs.Api.getResource("periodical"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Periodical.Write",
      readAction: "Periodical.Read",
    },
    settings: {
      name: "PeriodicalSettings",
      route: "/periodical/settings",
      getResource: () => arxs.Api.getResource("periodical").settings(),
      readAction: "Periodical.Settings.Read",
      writeAction: "Periodical.Settings.Write",
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "reference", fullWidth: true },
          { name: "relationships" },
          { name: "schedule" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [ObjectDocumentType.AdditionalDocument],
    statuses: [
      PeriodicalStatus.InProcess,
      PeriodicalStatus.OnHold,
      PeriodicalStatus.Active,
      PeriodicalStatus.Completed,
    ],
    actions: PeriodicalActions.getActions(OriginModuleEnum.Periodical),
    wizard: {
      wizardClass: PeriodicalWizard,
      steps: [
        {
          title: arxs.t("details_pane.reference"),
          fields: [
            [
              {
                name: "reference",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Task,
                    OriginModuleEnum.PeriodicControl,
                    OriginModuleEnum.PeriodicMaintenance,
                    OriginModuleEnum.RiskAnalysis
                  ],
                  overridePrefilter: true,
                  isTemplate: true,
                  showOpenInTab: false,
                  singleSelection: true,
                  required: true,
                },
                onChange: (stateProxy, name, child, fieldName, context) => {
                  PeriodicalWizard.templatify(stateProxy, "reference", context);
                },
              },
              {
                name: "status",
                values: [
                  PeriodicalStatus.InProcess,
                  PeriodicalStatus.Active,
                  PeriodicalStatus.OnHold,
                  PeriodicalStatus.Completed,
                ],
                props: { required: true, disabledOnEdit: true },
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
          // getSecurityContext: (writeAction, getValue) => {
          //   const args = ["legalStructure", "branch"].map(getValue);
          //   return arxs.securityContext.buildForContext(
          //     writeAction,
          //     ...(args as any)
          //   );
          // },
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                  overridePrefilter: true,
                },
              },
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.recurrency"),
          fields: [
            [
              {
                name: "schedule",
                props: {
                  notificationPattern: {
                    title: arxs.t("wizard.field.creation_pattern"),
                  },
                  allowedRecurrencePatternValues: ["Daily", "Weekly", "Monthly", "Yearly"],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [[{ name: "documents", type: "document" }]],
        },
      ],
      lookups: {
        taskMap: {},
        maintenanceMap: {},
        inspectionMap: {},
        codeElementsById: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
      },
      addNewState: "Active",
    },
    board: {
      lookups: {
        tagMap: {},
        periodicals: [],
        employeeMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        labourmeans: [],
        equipments: [],
        intangibleAssets: [],
        protectionEquipments: [],
        hazardousSubstances: [],
        combinedInstallations: [],
        codeElementsById: {},
        userRoleMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "referenceModule" },
        { name: "legalStructures" },
        { name: "branches" },
        { name: "buildings" },
        { name: "locations" },
        { name: "subjectUniqueNumber" },
        { name: "geoLocation" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "responsible" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: PeriodicalBoard,
      bucketConfig: [
        {
          module: module,
          status: PeriodicalStatus.InProcess,
          onDrop: (state) =>
            PeriodicalActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
        {
          module: module,
          status: PeriodicalStatus.Active,
          onDrop: (state) =>
            PeriodicalActions.dropActions().onDropInActive(state),
          requiredActions: ["unhold", "activate"],
        },
        {
          module: module,
          status: PeriodicalStatus.OnHold,
          onDrop: (state) =>
            PeriodicalActions.dropActions().onDropInOnHold(state),
          requiredActions: ["hold"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
    },
    cardClass: PeriodicalCard,
  };
};
export default getPeriodicalMetadata;
