import arxs from "infra/arxs";
import { OriginModuleEnum, LinkType, MultiYearPlanStatus } from "infra/api/contracts";
import Toaster from "components/util/Toaster";
import GlobalActions from "modules/GlobalActions";
import ClassicActions from "modules/ClassicActions";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { createPlanMultiYearPlanPopup } from "./PlanMultiYearPlanPopup";

class MultiYearPlanActions {
  getActions = (module, excludeGlobals) => {
    return GlobalActions.getActions(module)
      .concat(ClassicActions.getActions(module))
      .filter((_) => !excludeGlobals)
      .concat([
        {
          name: "plan",
          module: module,
          icon: arxs.actions.icons["plan"],
          getTitle: () => arxs.actions.titles["plan"],
          onClick: (state) => this.plan(state),
        },
        {
          name: "unplan",
          module: module,
          icon: arxs.actions.icons["unplan"],
          getTitle: () => arxs.actions.titles["unplan"],
          onClick: (state) => this.unplan(state),
        },
        {
          name: "replan",
          module: module,
          icon: arxs.actions.icons["replan"],
          getTitle: () => arxs.actions.titles["replan"],
          onClick: (state) => this.replan(state),
        },
        {
          name: "complete",
          module: module,
          icon: arxs.actions.icons["complete"],
          getTitle: () => arxs.actions.titles["complete"],
          onClick: (state) => this.complete(state),
        },
        {
          name: "close",
          module: module,
          icon: arxs.actions.icons["close"],
          getTitle: () => arxs.actions.titles["close"],
          onClick: (state) => this.close(state),
        },
      ]);
  };

  getGlobalActions = (module) => {
    // add only when modules enabled in VNEXT
    if (arxs.moduleMetadataRegistry.isModuleAllowed(OriginModuleEnum.GlobalPreventionPlan)) {
      return [{
        name: "create_myp",
        module: module,
        icon: "far fa-globe",
        getTitle: () => arxs.t("kanban.actions.add_myp_item"),
        onClick: (state) => this.createMultiYearPlanItem(state, module),
        singleSelectionOnly: true
      }];
    };

    return [];
  }

  createMultiYearPlanItem = (state, module) => {
    let { cards, history, item } = state;

    if (!cards && item){
      cards = [item.card];
    }

    if (cards.length === 1) {
      const {
        id,
        module,
        legalStructureId,
        branchId,
        buildingId,
        locationId,
        geoLocation,
        subject,
      } = cards[0];

      const status = "ToPlan";
      const state =  {
        legalStructure: { id: legalStructureId },
        branch: { id: branchId },
        building: { id: buildingId },
        location: { id: locationId },
        geoLocation,
        subject,
        status,
        origins: [{ id, module, type: LinkType.Origin }],
      };
      history.push({ pathname: `/multiyearplan/create`, state });
    }
    return new Promise(() => { });
  }

  plan = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const planPopup = createPlanMultiYearPlanPopup(
      { ids },
      (data) => {
        arxs.ApiClient.safety.multiYearPlan
          .plan(data)
          .then(() =>
            Toaster.success(arxs.t("actions.multi_year_plan.plan_popup.success"))
          );

        context.popup.close();
      },
      context.popup.close
    );

    context.popup.show(planPopup);
  }

  unplan = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.multiYearPlan
        .unplan(data)
        .then(() =>
          Toaster.success(arxs.t("actions.multi_year_plan.unplan_popup.success"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.multi_year_plan.unplan_popup.confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );
    context.inputPopup.show(confirmation);
  }

  replan = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const planPopup = createPlanMultiYearPlanPopup(
      { ids },
      (data) => {
        arxs.ApiClient.safety.multiYearPlan
          .replan(data)
          .then(() =>
            Toaster.success(arxs.t("actions.multi_year_plan.replan_popup.success"))
          );
          context.popup.close();
      },
      context.popup.close,
      true
    );

    context.popup.show(planPopup);
  }

  complete = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.multiYearPlan
        .complete(data)
        .then(() =>
          Toaster.success(arxs.t("actions.multi_year_plan.complete_popup.success"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.multi_year_plan.complete_popup.confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      false,
      arxs.t("common.motivation")
    );
    context.inputPopup.show(confirmation);
  }

  close = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.multiYearPlan
        .close(data)
        .then(() =>
          Toaster.success(arxs.t("actions.multi_year_plan.close_popup.success"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.multi_year_plan.close_popup.confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );
    context.inputPopup.show(confirmation);
  }

  dropActions = () => {
    return {
      onDropInToPlan: (state) => {
        this.unplan(state);
      },
      onDropInClosed: (state) => {
        this.close(state);

      },
      onDropInPlanned: (state) => {
        switch (state.item.status) {
          case MultiYearPlanStatus.ToPlan:
            this.plan(state);
            break;
          default:
            this.replan(state);
        }
      },
      onDropInCompleted: (state) => {
        this.complete(state);
      },
    };
  }
}
export default new MultiYearPlanActions();
