import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import posthog from "posthog-js";

import "infra/ArrayExtensions";
import arxs from "infra/arxs";
import { Feature } from "infra/Features";
import GlobalContext from "infra/GlobalContext";
import { AttachmentType, OriginModuleEnum, StatusEnum, } from "infra/api/contracts";
import buildSearchTermFilter from "infra/SearchTermFilter.js";

import Badge from "components/controls/Badge";
import Button from "components/controls/Button";
import Spacer from "components/controls/Spacer";
import DropDownMenu from "components/controls/DropDownMenu";
import KanBan from "components/layouts/kanban/KanBan";
import Tree from "components/layouts/tree/Tree";
import Grid from "components/layouts/grid/Grid";
import Gantt from "components/layouts/gantt/Gantt";
import { createCardLookup } from "components/shell/CardLookup/CardLookup";
import { createTagLookup } from "components/controls/tags/TagLookup";
import { createUpload } from "components/controls/upload/Upload";
import { createWeblinkPopup } from "components/controls/documents/WeblinkPopup";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { createBulkEditRelationshipsPopup } from "components/bulkeditor/BulkEditRelationshipsPopup";
import Views from "components/board/Views";
import ViewsRenderer from "components/board/ViewsRenderer";
import CheckBox from "components/controls/CheckBox";
import MapView from "components/shell/MapView/MapView";
import Toaster from "components/util/Toaster";
import { BoardViewType } from "modules/ModuleMetadata";
import BoardExcelExport from "components/board/export/BoardExcelExport";
import { createLibraryPopup } from "components/controls/library/LibraryPopup";

import "./Board.scss";

const _visibilityPredicates = {
  all: (_) => true,
  standard: (x) => !x.isDeleted,
  archived: (x) => x.isDeleted,
};

const gridStatusFilterAction = {
  add: "add",
  remove: "remove",
};

export default class Board extends Component {
  MODULE_SETTINGS_KEY = "board.layout.user";

  constructor(props) {
    super(props);

    const gridState = {
      result: { data: [] },
      dataState: {
        skip: 0,
        take: 30,
        sort: [{ field: "uniqueNumber" }],
      },
    };

    const module = this.props.module;
    const metadata = arxs.moduleMetadataRegistry.get(module);

    const buckets = this.preprocessBuckets(
      metadata.statuses.concat(metadata.board.additionalStatuses || [])
    );

    const gridColumns = metadata.board.gridColums || ["uniqueNumber"];

    const columnDefinitions = gridColumns
      .map(this.mapToColumnDefinition)
      .toDictionary(
        (field) => field.name,
        (field) => ({ width: field.width, title: field.title })
      );

    const visibleBuckets = buckets.toDictionary(
      (bucket) => bucket,
      (_) => ({})
    );

    this.state = {
      module: module,
      title: metadata.title || arxs.modules.titles[module],
      icon: arxs.modules.icons[module],

      metadata,

      pristine: [],
      cards: [],
      lists: [],
      checked: {},
      filterState: {},
      allowCreate: metadata.board.allowCreate === false ? false : true,
      gridColumns,
      buckets,

      // views stuff
      views: [],
      activeViewMode: null,
      isVisible: _visibilityPredicates.standard,
      gridState,
      skip: 0,
      take: 30,
      columnDefinitions,
      visibleBuckets,
      dirtyVisibleBuckets: visibleBuckets,
      visibleCards: [],

      tabs: [],

      // Is set to true when pristine is set by the lookup callback
      loaded: false,
    };

    this.state.activeViewMode = this.getViewModes()[0];

    this.views = new Views(
      arxs,
      createInputPopup,
      () => this.state,
      (state, skipRefresh) =>
        new Promise((resolve) => {
          if (state.searchTerm !== undefined && state.searchTerm !== null) {
            state.searchTermFilter = buildSearchTermFilter(state.searchTerm);
          }
          this.setState(state, () => {
            if (resolve) resolve();
            if (!skipRefresh) {
              this.refresh();
            }
          });
        }),
      module
    );
  }

  navigateToRoute = () => {
    const module = this.props.module;
    const rawSearchTerm = ((this.props.match || {}).params || {}).searchTerm;
    if (!rawSearchTerm) {
      return;
    }

    let searchTerm = rawSearchTerm
      ? decodeURIComponent(rawSearchTerm)
      : undefined;
    let ref;

    if (
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        searchTerm
      )
    ) {
      const id = searchTerm;
      ref = { id, module };
    } else if (/^[a-z]{3}-[0-9]{6}$/i.test(searchTerm)) {
      const uniqueNumber = (searchTerm || "").toUpperCase();
      const record =
        arxs.Api.lookups.resolveSubjectByUniqueNumber(uniqueNumber);
      if (record) {
        ref = { id: record.id, module: record.module };
      }
    }

    if (ref) {
      this.setSelectedCard(ref);
      searchTerm = undefined;
    }

    this.setState({
      rawSearchTerm,
      searchTerm,
      searchTermFilter: buildSearchTermFilter(searchTerm),
    });
  };

  onLoaded = () => {
    this.navigateToRoute();
  };

  preprocessBuckets = (buckets) => {
    if (
      buckets.contains(StatusEnum.ToCommission1) ||
      buckets.contains(StatusEnum.ToCommission2)
    ) {
      return buckets
        .filter(
          (x) =>
            x !== StatusEnum.ToCommission1 && x !== StatusEnum.ToCommission2
        )
        .concat(StatusEnum.ToCommission1);
    }
    return buckets;
  };

  setterQueue = [];

  stateProxy = {
    getter: (propName) => {
      return this.state[propName];
    },
    setter: (stateProp, callBack) => {
      const queue = this.setterQueue;
      if (queue.length > 0) {
        clearTimeout(queue[queue.length - 1].timeout);
      }

      const timeout = setTimeout(() => {
        const aggregated = queue.reduce(
          (p, c) => ({ ...p, ...c.stateProp }),
          {}
        );
        this.setterQueue = [];

        // Only set this to true once. When pristine is loaded.
        const loaded = !!aggregated.pristine || stateProp.loaded;

        if (!stateProp.loaded && loaded) {
          this.onLoaded();
        }

        this.setState(
          { ...aggregated, loaded },
          callBack
            ? () => {
                callBack();
                this.enrichCards().then(this.refresh);
              }
            : () => {
                this.enrichCards().then(this.refresh);
              }
        );
      }, 50);

      this.setterQueue.push({ timeout, stateProp });
    },
  };

  mapToColumnDefinition = (nameOrDefinition) => {
    if (typeof nameOrDefinition === "string") {
      return { name: nameOrDefinition };
    }
    return nameOrDefinition;
  };

  componentDidMount() {
    this.views.initialize();

    const { metadata } = this.state;
    let lookups = metadata.board.lookups || {};
    lookups.reportsByAliasMap = {};

    metadata.board.boardClass.loadData(lookups, this.stateProxy).then((x) => {
      this.subscriptions = x;
      this.setState({ ...x });
    });

    arxs.Api.moduleSettings
      .getValue(this.state.metadata.module, this.MODULE_SETTINGS_KEY)
      .then((settings) => {
        const state = this.deserializeFromModuleSetting(settings);

        this.setState(state, this.refresh);
      });
  }

  updateStateAndRefresh = (object) => {
    this.setState({ ...object }, this.refresh);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pristine !== this.state.pristine) {
      this.updateStateAndRefresh({ searchTerm: undefined });
    }

    const rawSearchTerm = ((this.props.match || {}).params || {}).searchTerm;
    if (
      this.state.rawSearchTerm &&
      this.state.rawSearchTerm !== rawSearchTerm
    ) {
      this.navigateToRoute();
    }

    if (this.views) {
      this.views.detectChanges();
    }
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      if (this.subscriptions.lookups) {
        this.subscriptions.lookups.dispose();
      }
      if (this.linkSubscriptions) {
        for (const subscription of Object.values(this.linkSubscriptions)) {
          subscription.dispose();
        }
      }
    }
  }

  deserializeFromModuleSetting = (payload) => {
    if (!payload) {
      return {};
    }

    const activeViewMode =
      (this.props.isMobile
        ? payload.mobileActiveViewMode
        : payload.desktopActiveViewMode) || this.getViewModes()[0];

    return {
      activeViewMode: activeViewMode,
      desktopActiveViewMode: payload.desktopActiveViewMode,
      mobileActiveViewMode: payload.mobileActiveViewMode,
    };
  };

  serializeToModuleSetting = (state) => {
    if (this.props.isMobile) {
      return {
        mobileActiveViewMode: state.activeViewMode,
        desktopActiveViewMode: this.state.desktopActiveViewMode,
      };
    } else {
      return {
        desktopActiveViewMode: state.activeViewMode,
        mobileActiveViewMode: this.state.mobileActiveViewMode,
      };
    }
  };

  setStateAndPersist = (state, callback) => {
    this.setState(state, () => {
      if (callback) callback();
      arxs.Api.moduleSettings.setValue(
        this.state.metadata.module,
        this.MODULE_SETTINGS_KEY,
        this.serializeToModuleSetting(this.state),
        true
      );
    });
  };

  updateGridStatusFilter = (status, action) => {
    let { gridState, visibleBuckets } = this.state;
    const gridFilters = gridState.dataState.filter;

    const statusFilterParent =
      (gridFilters &&
        gridFilters.filters &&
        gridFilters.filters.filter((x) =>
          x.filters.some((y) => y.field === "status")
        )[0]) ||
      {};
    let newStatusFilterParent = {};

    if (
      statusFilterParent.filters === undefined ||
      statusFilterParent.filters === null ||
      statusFilterParent.filters.length === 0
    ) {
      //als we geen filters op status hebben van de grid, dan voegen we alle statuses toe van de buckets die nog aanstaan
      newStatusFilterParent = {
        logic: "or",
        filters: Object.keys(visibleBuckets).map((status) => ({
          field: "status",
          operator: "eq",
          value: status,
        })),
      };
    } else {
      switch (action) {
        case gridStatusFilterAction.remove:
          //als we een bucket uitvinken, dan verwijderen we die specifieke status uit de array (mocht die bestaan)
          newStatusFilterParent = {
            ...statusFilterParent,
            filters: statusFilterParent.filters.filter(
              (x) => x.value !== status
            ),
          };
          break;
        case gridStatusFilterAction.add:
          // als we een bucket aanvinken, dan voegen we die toe aan de array (als die nog niet zou bestaan)
          if (!statusFilterParent.filters.some((x) => x.value === status)) {
            newStatusFilterParent = {
              ...statusFilterParent,
              filters: statusFilterParent.filters.concat([
                { field: "status", operator: "eq", value: status },
              ]),
            };
          } else {
            newStatusFilterParent = { ...statusFilterParent };
          }
          break;
        default:
          break;
      }
    }

    const dataState = gridState.dataState;
    const mainFilter = dataState.filter;
    let newMainFilter = {};

    if (mainFilter === null || mainFilter === undefined) {
      newMainFilter = { filters: [newStatusFilterParent], logic: "and" };
    } else {
      const filtersWithoutStatus = mainFilter.filters.filter((x) =>
        x.filters.some((y) => y.field !== "status")
      );
      const newFilters =
        filtersWithoutStatus.length === 0
          ? [newStatusFilterParent]
          : filtersWithoutStatus.concat([newStatusFilterParent]);
      newMainFilter = { logic: "and", ...mainFilter, filters: newFilters };
    }

    const newDataState = {
      group: [],
      sort: [],
      ...dataState,
      filter: { ...newMainFilter },
    };

    const newGridState = this.views.createGridState(
      this.state.cards,
      newDataState
    );

    this.setState({ gridState: { ...newGridState } }, this.refresh);
  };

  getViewModes = () =>
    this.state.metadata.board.views || [BoardViewType.KanBan];

  enrichCards = () => {
    return new Promise((resolve) => {
      const cards = this.state.pristine
        .map((card) => ({
          ...card,
          key: card.id,

          module: card.module || this.state.module,
          moduleIcon: this.state.icon,
          moduleTitle: this.state.title,
        }))
        .map((card) =>
          this.state.metadata.board.boardClass.enrichCard(card, this.stateProxy)
        );
      this.setState({ cards }, () => {
        if (resolve) resolve();
      });
    });
  };

  refresh = () => {
    const filterEmpty = Object.keys(this.state.filterState).length === 0;

    const cards = this.state.cards
      .filter(
        (x) =>
          !this.state.searchTermFilter ||
          this.state.searchTermFilter(
            x,
            this.state.metadata.board.boardClass.getCardSearchTerms(x)
          )
      )
      .filter((x) => filterEmpty || this.state.filterState[x.id]);

    const allMatching = process(cards, {
      ...this.state.gridState.dataState,
      skip: 0,
      take: 100000,
    }).data;

    const checked = this.state.checked;

    for (const id of Object.keys(checked)) {
      const card = allMatching.filter((x) => x.id === id)[0];
      if (card) {
        card.checked = true;
      } else {
        delete checked[id];
      }
    }

    const statuses = this.state.buckets;

    let lists;

    const { activeViewMode } = this.state;

    if (statuses.length !== 0) {
      lists = statuses
        .filter((status) =>
          Object.keys(this.state.visibleBuckets).some(
            (x) =>
              x ===
              (status === StatusEnum.ToCommission2
                ? StatusEnum.ToCommission1
                : status)
          )
        )
        .map((status) => {
          let items = allMatching.filter(
            (x) =>
              (x.status === StatusEnum.ToCommission2
                ? StatusEnum.ToCommission1
                : x.status) === status && !x.isDeleted
          );
          const key = `list-${status}`;

          return {
            key: key,
            title: arxs.statuses.titles[status],
            status: status,
            cards: items,
          };
        });
    } else {
      const items = allMatching.filter((x) => !x.isDeleted);

      lists = [
        {
          key: "list",
          cards: items,
        },
      ];
    }

    let gridState = this.state.gridState || {};
    let tabs = this.state.tabs || [];
    let activeTab = this.state.activeTab || "";
    let visibleCards = this.state.visibleCards || [];

    if (activeViewMode === BoardViewType.Grid) {
      //grid stuff
      visibleCards = cards
        .filter(this.state.isVisible)
        .filter(
          (card) =>
            (statuses.some((x) => x) &&
              Object.keys(this.state.visibleBuckets).some(
                (x) =>
                  x ===
                  (card.status === StatusEnum.ToCommission2
                    ? StatusEnum.ToCommission1
                    : card.status)
              )) ||
            card
        );

      const filterStatus = (card, x) =>
        !card.isDeleted &&
        x.statuses.contains(
          card.status === StatusEnum.ToCommission2
            ? StatusEnum.ToCommission1
            : card.status
        );

      const statusTabs = Object.entries(
        statuses
          .filter((status) =>
            Object.keys(this.state.visibleBuckets).some(
              (x) =>
                x ===
                (status === StatusEnum.ToCommission2
                  ? StatusEnum.ToCommission1
                  : status)
            )
          )
          .map((status) => ({
            status,
            label: arxs.statuses.titles[status],
            className: arxs.getStatusClassName(this.props.module, status),
          }))
          .groupBy((status) => status.label)
      )
        .map((pair) => ({
          label: pair[0],
          className: pair[1][0].className,
          statuses: pair[1].map((x) => x.status),
        }))
        .map((x) => ({
          label: x.label,
          className: x.className,
          count: allMatching.filter((card) => filterStatus(card, x)).length,
          onClick: () =>
            this.setState(
              {
                isVisible: (card) => filterStatus(card, x),
                gridState: this.views.createGridState(visibleCards, {
                  ...this.state.gridState.dataState,
                  skip: 0,
                }),
              },
              this.refresh
            ),
        }));

      tabs = [
        {
          label: arxs.t("kanban.common.standard_records"),
          count: allMatching.filter(_visibilityPredicates.standard).length,
          onClick: () =>
            this.setState(
              {
                isVisible: _visibilityPredicates.standard,
                gridState: this.views.createGridState(visibleCards, {
                  ...this.state.gridState.dataState,
                  skip: 0,
                }),
              },
              this.refresh
            ),
        },
        {
          label: arxs.t("kanban.common.all_records"),
          count: allMatching.filter(_visibilityPredicates.all).length,
          onClick: () =>
            this.setState(
              {
                isVisible: _visibilityPredicates.all,
                gridState: this.views.createGridState(visibleCards, {
                  ...this.state.gridState.dataState,
                  skip: 0,
                }),
              },
              this.refresh
            ),
        },
        {
          label: arxs.t("kanban.common.archived_records"),
          count: allMatching.filter(_visibilityPredicates.archived).length,
          onClick: () =>
            this.setState(
              {
                isVisible: _visibilityPredicates.archived,
                gridState: this.views.createGridState(visibleCards, {
                  ...this.state.gridState.dataState,
                  skip: 0,
                }),
              },
              this.refresh
            ),
        },
      ].concat(statusTabs);

      activeTab = this.state.activeTab || tabs[0].label;

      gridState = this.views.createGridState(
        visibleCards,
        this.state.gridState.dataState
      );
    }

    this.setState({
      lists,
      checked,
      gridState,
      tabs,
      activeTab,
      visibleCards,
    });
  };

  getCardProps = () => {
    return this.state.metadata.board.cardProps || {};
  };

  getEmployeeValue = (refs) => {
    const { employeeMap } = this.state;
    if (!refs || !employeeMap) return "";
    if (refs.length) {
      return refs
        .map((ref) => ref.employee)
        .map((employee) => this.getLookupValue(employeeMap, employee))
        .join(", ");
    }
    return this.getLookupValue(employeeMap, refs);
  };

  getCheckedCards = () => {
    const { cards, checked } = this.state;
    const checkedCards = cards.filter((card) => checked[card.id]);
    return checkedCards;
  };

  setSelectedCard = (selected) => {
    this.context.detailsPane.open(selected);
  };

  setCheckedCards = (checked) => {
    const cards = this.state.cards;
    for (let card of cards) {
      card.checked = !!checked[card.id];
    }

    this.setState({ cards, checked }, this.onChangeCheckedSelection);
  };

  onChangeCheckedSelection = () => {
    //dit doet niet veel?
    if (this.props.onCheckedCards) {
      const cards = this.getCheckedCards();
      this.props.onCheckedCards(cards);
    }
  };

  clearCheckedCards = () => {
    this.setCheckedCards({});
  };

  handleCardCheck = (event, card) => {
    var checked = this.state.checked;
    if (checked[card.id]) {
      delete checked[card.id];
    } else {
      checked[card.id] = true;
    }

    this.setCheckedCards(checked);
  };

  handleCardToggle = (e, card) => {
    e &&
      e.syntheticEvent &&
      e.syntheticEvent.stopPropagation() &&
      e.syntheticEvent.preventDefault();
    let selected = { id: card.id, module: card.module };

    this.setSelectedCard(selected);
  };

  navigateDetailsPane = (e, card, tab) => {
    if (this.props.onDetailsPaneNavigate) {
      let selected = { id: card.id, module: card.module };
      this.setSelectedCard(selected);
      this.props.onDetailsPaneNavigate(tab);
    }
  };

  setViewMode = (viewMode) => {
    this.setStateAndPersist({ activeViewMode: viewMode }, this.refresh);
  };

  handleChangeSearchTerm = (event) => {
    const searchTerm = event.target.value;
    const searchTermFilter = buildSearchTermFilter(searchTerm);
    this.views.setState({ searchTerm, searchTermFilter });
    // this.setState({ searchTerm, searchTermFilter }, this.refresh);
  };

  getValue = (map, cardValue) => {
    const id = (cardValue || {}).id;
    const ref = (map && map[id]) || {};
    return ref.name;
  };

  getFilteredActions = (actionsByCard) => {
    const preprocessedCardActions = actionsByCard
      // actions suffixed with :rec are record-based actions and cannot be executed in bulk
      .map((actions, i) =>
        actions.map((action) => action.replace(/:rec/, `:${i}`))
      );

    const allowedActions = preprocessedCardActions.some((x) => x)
      ? preprocessedCardActions
          .slice(1)
          .reduce(
            (intersection, actions) => intersection.intersect(actions),
            preprocessedCardActions.slice(0, 1).flatMap((x) => x)
          )
          .map((action) => action.replace(/:.*/, ""))
      : [];
    return allowedActions;
  };

  handleAddToSelection = (context, cards) => {
    const canEdit = cards.reduce(
      (canEdit, card) => canEdit && card.actions.includes("edit"),
      true
    );
    if (!canEdit) {
      return [];
    }

    const options = [];
    if (arxs.isActionAllowed("Tag.Assign")) {
      options.push({
        title: arxs.t("kanban.add_circle.add_tags"),
        handle: () => this.handleAddTags(context),
      });
    }

    if (this.state.module !== OriginModuleEnum.Document) {
      options.push({
        title: arxs.t("kanban.add_circle.upload_files"),
        handle: () => this.handleUploadFiles(context),
      });
      options.push({
        title: arxs.t("kanban.add_circle.add_documents"),
        handle: () => this.handleAddDocuments(context),
      });
      options.push({
        title: arxs.t("kanban.add_circle.add_weblinks"),
        handle: () => this.handleAddWeblinks(context),
      });
    }

    const types = this.getRelationshipTypes();
    if (types.length > 0) {
      options.push({
        title: arxs.t("kanban.add_circle.bulk_edit_relationships"),
        handle: () => this.handleBulkEditRelationships(context),
      });
    }

    context.optionPopup.show(
      arxs.t("kanban.actions.add_to_selection"),
      options
    );
  };

  handleAddTags = (context) => {
    const module = this.state.module;
    const cards = this.getCheckedCards();
    const onSubmit = (state) => {
      context.popup.close();
    };
    let tagLookup = createTagLookup(module, cards, onSubmit);
    this.setState({ tagLookup }, () =>
      context.popup.show(this.state.tagLookup)
    );
  };

  getRelationshipTypes = () => {
    const relationshipField = this.state.metadata.wizard.steps
      .flatMap((x) => x.fields || [])
      .flatMap((x) => x)
      .filter((x) => x.name === "relationships")[0];

    if (relationshipField) {
      const types = relationshipField.props.types;
      return types;
    }

    return [];
  };

  handleBulkEditRelationships = (context) => {
    const cards = this.getCheckedCards();
    const onSubmit = (state) => {
      context.popup.close();
    };

    const types = this.getRelationshipTypes();
    if (types.length > 0) {
      let bulkEditRelationshipsPopup = createBulkEditRelationshipsPopup(
        cards,
        types,
        onSubmit,
        onSubmit
      );
      this.setState({ bulkEditRelationshipsPopup }, () =>
        context.popup.show(this.state.bulkEditRelationshipsPopup)
      );
    }
  };

  handleAddAttachments = (attachmentPayload) => {
    arxs.ApiClient.shared.attachment
      .addAttachmentsToObjects(attachmentPayload)
      .then((x) =>
        Toaster.success(arxs.t("kanban.actions.attachments_added_success"))
      );
  };

  handleAddDocuments = (context) => {
    const cards = this.getCheckedCards();

    const onApplyFilter = (added, documentType) => {
      let attachmentInfo = { attachments: [], documents: [], storedFiles: [] };

      const addedMap = {};
      for (const key of Object.keys(added)) {
        addedMap[key] = arxs.uuid.generate();
      }

      attachmentInfo.attachments = [
        {
          type: documentType.id,
          value: Object.keys(added).map((x) => ({
            id: addedMap[x],
            props: {},
            isDeleted: false,
            type: AttachmentType.Document,
          })),
        },
      ];
      attachmentInfo.documents = Object.keys(added).map((x) => ({
        id: addedMap[x],
        documentId: x,
      }));

      this.handleAddAttachments({
        refs: cards.map((x) => ({ objectId: x.id, module: x.module })),
        attachmentInfo,
      });

      context.popup.close();
    };

    const securityContext = arxs.securityContext.buildForMultipleContext(
      undefined,
      [],
      [],
      [],
      [],
      cards
    );

    const cardLookup = createCardLookup({
      modules: [OriginModuleEnum.Document],
      onApplyFilter,
      securityContext,
      selectedCards: cards,
      viewMode: "tree",
      sourceModule: this.state.module,
    });
    this.setState({ cardLookup }, () =>
      context.popup.show(this.state.cardLookup)
    );
  };

  handleUploadFiles = (context) => {
    const module = this.state.module;
    const cards = this.getCheckedCards();
    const onSubmit = (files) => {
      context.popup.close();

      let attachmentInfo = { attachments: [], documents: [], storedFiles: [] };

      var filesWithId = files.documents.map((x) => ({
        ...x,
        id: arxs.uuid.generate(),
      }));

      attachmentInfo.storedFiles = filesWithId.map((x) => ({
        id: x.id,
        contentType: x.contentType,
        url: x.previewUrl,
        name: x.name,
        hash: x.hash,
      }));

      for (const type of filesWithId.map((x) => x.type).distinct((x) => x)) {
        let addedForType = filesWithId.filter((x) => x.type === type);
        attachmentInfo.attachments.push({
          type: type,
          value: addedForType.map((x) => ({
            id: x.id,
            props: x.isPreferredImage ? { preferredImage: "true" } : {},
            isDeleted: false,
            type: AttachmentType.StoredFile,
          })),
        });
      }

      this.handleAddAttachments({
        refs: cards.map((x) => ({ objectId: x.id, module: x.module })),
        attachmentInfo,
      });
    };
    let upload = createUpload(module, cards, onSubmit);
    this.setState({ upload }, () => context.popup.show(this.state.upload));
  };

  handleAddWeblinks = (context) => {
    const cards = this.getCheckedCards();
    const module = this.state.module;

    const popup = createWeblinkPopup(
      module,
      (result) => {
        context.popup.close();
        const parsedUrl = arxs.parseURL(result.weblink);

        let attachmentInfo = {
          attachments: [],
          documents: [],
          storedFiles: [],
        };

        attachmentInfo.attachments.push({
          type: result.documentType,
          value: [
            {
              id: arxs.uuid.generate(),
              props: { name: parsedUrl.hostname, url: result.weblink },
              isDeleted: false,
              type: AttachmentType.Weblink,
            },
          ],
        });

        this.handleAddAttachments({
          refs: cards.map((x) => ({ objectId: x.id, module: x.module })),
          attachmentInfo,
        });
      },
      () => {
        context.popup.close();
      }
    );

    context.popup.show(popup);
  };

  handleAction = (context, cards, moduleActions, action) => {
    const idsForModule = cards.groupBy(
      (x) => x.module,
      (x) => x.id
    );

    for (const module of Object.keys(idsForModule)) {
      const ids = idsForModule[module];
      const actionState = { context: context, ids: ids, cards: cards, stateProxy: this.stateProxy };
      const actionItem = moduleActions.filter(
        (x) => x.name === action && x.module === module
      )[0];

      actionItem.onClick(actionState, this.props.history);
    }

    if (action === "archive") {
      this.setState({ checked: {} }, this.refresh);
    } else {
      this.refresh();
    }
  };

  canDrop = (droppingStatus, card) => {
    if (
      !this.state.metadata.board.bucketConfig ||
      droppingStatus === card.status
    ) {
      return false;
    }

    const bucketConfigs = this.state.metadata.board.bucketConfig.filter(
      (config) =>
        [this.state.module, card.module].contains(config.module) &&
        config.status === droppingStatus
    );

    const cleanedCardActions = card.actions.map((x) => x.split(":")[0]);

    if (bucketConfigs && bucketConfigs.length === 1) {
      if (
        bucketConfigs[0].requiredActions.intersect(cleanedCardActions).length >
        0
      ) {
        return true;
      }
    }

    return false;
  };

  onDrop = (droppedStatus, item, context, history) => {
    const actionState = {
      context: context,
      item: item,
      history: history,
    };

    const filteredActions = this.getFilteredActions([item.actions]);

    if (this.canDrop(droppedStatus, item)) {
      const dropAction = this.state.metadata.board.bucketConfig.filter(
        (x) =>
          x.status === droppedStatus &&
          filteredActions.intersect(x.requiredActions)
      )[0];
      dropAction.onDrop(actionState);
    }
  };

  renderOperations = (context) => {
    const { activeViewMode, metadata } = this.state;
    const cards = this.getCheckedCards();
    const ids = cards.map((x) => x.id);
    const count = cards.length;
    const cardModules = cards.map((x) => x.module).distinct();

    const filteredActions = this.getFilteredActions(
      cards.map((x) => x.actions)
    ).filter((x) => cardModules.length === 1);

    let moduleActions = metadata.actions.filter(
      (x) => x.module === cardModules[0]
    );

    const actionState = {
      context: context,
      ids: ids,
      cards: cards,
      history: this.props.history,
    };

    const canWrite = arxs.isActionAllowed(metadata.base.writeAction);

    const canArchive = filteredActions.some((x) => x === "archive");
    const archiveAction =
      canArchive && moduleActions.filter((x) => x.name === "archive")[0];
    const canReactivate = filteredActions.some((x) => x === "reactivate");
    const reactivateAction =
      canReactivate && moduleActions.filter((x) => x.name === "reactivate")[0];

    //exclude actions above from single buttons and edit from detailspane
    const actionItems = moduleActions
      .filter((x) => x)
      .filter(
        (x) =>
          x.name !== "reactivate" && x.name !== "edit" && x.name !== "archive"
      )
      .filter((x) =>
        (x.applicableViews || Object.values(BoardViewType)).contains(
          activeViewMode
        )
      )
      .filter((x) => !x.singleSelectionOnly || count === 1)
      .filter((x) => filteredActions.contains(x.name));

    return (
      !!count && (
        <div className="board-action-toolbar">
          <div className="section">
            <i className="far fa-times" onClick={this.clearCheckedCards}></i>
          </div>
          <div className="section">
            <Badge>{count}</Badge>
            {!context.platform.isMobile && arxs.t("kanban.cards_selected")}
          </div>
          {canArchive && (
            <Button
              className="section"
              onClick={() =>
                this.handleAction(context, cards, moduleActions, "archive")
              }
              title={archiveAction.getTitle()}
            >
              <i className="far fa-archive"></i>
            </Button>
          )}
          {canReactivate && (
            <Button
              className="section"
              onClick={() =>
                this.handleAction(context, cards, moduleActions, "reactivate")
              }
              title={reactivateAction.getTitle()}
            >
              <i className="far fa-inbox-out"></i>
            </Button>
          )}
          {canWrite && (
            <Button
              className="section"
              onClick={() => this.handleAddToSelection(context, cards)}
              title={arxs.t("actions.add_to_selection")}
            >
              <i className="far fa-plus-circle"></i>
            </Button>
          )}
          {
            <BoardExcelExport
              module={this.props.module}
              data={cards}
              gridColumns={this.state.gridColumns}
              columnDefinitions={this.state.columnDefinitions}
            />
          }
          <DropDownMenu
            id="report-selector"
            className="above section action report"
            items={this.getReportItems()}
            // chevron={false}
          >
            <i className="far fa-file-chart-line"></i>
          </DropDownMenu>
          <DropDownMenu
            id="kanban-actions"
            className="above section action icon"
            items={actionItems}
            state={actionState}
          >
            <span>{arxs.t("kanban.actions.title")}</span>
          </DropDownMenu>
        </div>
      )
    );
  };

  handleAdvancedFilter = (context) => {
    const onApplyFilter = (state) => {
      this.setState({ filterState: state }, () => {
        this.refresh();
        context.popup.close();
      });
    };
    const cardLookup = createCardLookup({
      modules: [this.state.module],
      onApplyFilter,
      searchTerm: this.state.searchTerm,
    });
    this.setState({ cardLookup }, () =>
      context.popup.show(this.state.cardLookup)
    );
  };

  renderAddNewButton = (context) => {
    const canWrite =
      (this.state.metadata.base.createAction
        ? arxs.isActionAllowed(this.state.metadata.base.createAction)
        : arxs.isActionAllowed(this.state.metadata.base.writeAction)) &&
      this.state.allowCreate;
    if (canWrite) {
      return (
        <Fragment>
          <Spacer width="10px" />
          <Link
            to={{
              pathname: `${this.state.metadata.base.route}/create`,
              state:
                this.state.metadata.wizard.addNewState &&
                this.state.metadata.wizard.addNewState.length > 0
                  ? { status: this.state.metadata.wizard.addNewState }
                  : undefined,
            }}
          >
            <Button className="create" onClick={context.detailsPane.clear}>
              {!context.platform.isMobile && arxs.t("kanban.common.new")}{" "}
              <i className="fa fa-plus"></i>
            </Button>
          </Link>
        </Fragment>
      );
    }
    return <Fragment></Fragment>;
  };

  toggleItem = (e, selector) => {
    const { dirtyVisibleBuckets, columnDefinitions, activeViewMode } =
      this.state;

    e.stopPropagation();

    let definition = {};

    switch (activeViewMode) {
      case BoardViewType.KanBan:
        definition = dirtyVisibleBuckets[selector];
        if (definition) {
          delete dirtyVisibleBuckets[selector];
        } else {
          dirtyVisibleBuckets[selector] = true;
        }
        this.views.setState({ visibleBuckets: dirtyVisibleBuckets }, () =>
          this.updateGridStatusFilter(
            selector,
            definition
              ? gridStatusFilterAction.remove
              : gridStatusFilterAction.add
          )
        );
        break;
      case BoardViewType.Grid:
        definition = columnDefinitions[selector];

        let newDefinitions = { ...columnDefinitions };

        if (definition) {
          delete newDefinitions[selector];
        } else {
          newDefinitions[selector] = { field: selector };
        }
        this.views.setState({ columnDefinitions: newDefinitions });
        break;
      default:
        break;
    }
  };

  getTogglableItems = () => {
    const {
      buckets,
      gridColumns,
      visibleBuckets,
      columnDefinitions,
      activeViewMode,
    } = this.state;

    switch (activeViewMode) {
      case BoardViewType.KanBan:
        if (buckets && visibleBuckets) {
          return buckets.map((bucket) => ({
            column: bucket,
            content: (
              <div className="board-item-selector">
                <CheckBox
                  className="checkbox"
                  checked={!!visibleBuckets[bucket]}
                />
                {arxs.t(`statuses.${bucket}`)}
              </div>
            ),
            onClick: (e) => this.toggleItem(e, bucket),
          }));
        }
        break;
      case BoardViewType.Grid:
        if (gridColumns && columnDefinitions) {
          return gridColumns
            .filter((x) => !x.exportOnly)
            .map(this.mapToColumnDefinition)
            .map((x) => x.name)
            .map((column) => ({
              column: column,
              content: (
                <div className="board-item-selector">
                  <CheckBox
                    className="checkbox"
                    checked={!!columnDefinitions[column]}
                  />
                  {arxs.t(`kanban.common.${column}`)}
                </div>
              ),
              onClick: (e) => this.toggleItem(e, column),
            }));
        }
        break;
      default:
        break;
    }
  };

  renderViewControls = () => {
    return (
      <div className="board-toolbar">
        <div className="board-toolbar-views">
          <ViewsRenderer controller={this.views} />
          <DropDownMenu
            id="boardColumnSelector"
            className="board-toolbar-cog button"
            items={this.getTogglableItems()}
            chevron={false}
          >
            <i className="fas fa-cog"></i>
          </DropDownMenu>
        </div>
      </div>
    );
  };

  getReportItems = () => {
    const { module, checked, cards } = this.state;
    const { metadata, reportsByAliasMap } = this.state;

    // TODO: I don't think this is ever used.
    // There are no reports definitions with alwaysOn
    // Can we think of a usecase we want to support?
    let reportActions = (metadata.board.reports || [])
      .filter((x) => !!x.alwaysOn === true)
      .map((report) => ({
        content: (
          <div className="board-export-selector">
            <i className="far fa-file-alt"></i>
            {arxs.t(`report.${report.name}`)}
          </div>
        ),
        onClick: (e) => {
          Toaster.notify(arxs.t("report.requested"));
          const template = reportsByAliasMap[report.name][0];
          posthog.capture(`action:report_requested`, { module, template: template.alias });
          arxs.ReportClient.reporting().generatePDF({ template });
        },
      }));

    const checkedCards = this.getCheckedCards();

    let documentActions = (metadata.board.documents || []).map(
      (documentAction) => ({
        content: (
          <div className="board-export-selector">
            <i className="far fa-file-archive"></i>
            {arxs.documentTypes.titles[documentAction.type.toLowerCase()]}
          </div>
        ),
        onClick: (e) => {
          Toaster.notify(arxs.t("actions.report.documents_requested"));
          posthog.capture(`action:reports_requested`, { module });
          arxs.ReportClient.reporting().getZippedDocuments(module, checkedCards, documentAction.type);
        },
      })
    );

    if (checkedCards.length > 0) {
      const refs = checkedCards.map((x) => ({
        module: x.module,
        objectId: x.id,
      }));
      const matchesBoardModule = refs.all((x) => x.module === module);
      const filter = { refs: refs };

      const uniqueNumbers = cards
        .filter((x) => checked[x.id])
        .filter((x) => x.module === module)
        .map((x) => x.uniqueNumber);

      const moduleReports = (metadata.board.reports || [])
        .filter((x) => !!x.alwaysOn === false && matchesBoardModule)
        .filter((x) => !x.filter || x.filter(checkedCards))
        .map((report) => ({
          content: (
            <div className="board-export-selector">
              <i className="far fa-file-alt"></i>
              {arxs.t(`report.${report.name}`)}
            </div>
          ),
          onClick: (e) => {
            Toaster.notify(arxs.t("report.requested"));
            const template = reportsByAliasMap[report.name][0];
            posthog.capture(`action:report_requested`, { module, template: template.alias });
            arxs.ReportClient.reporting().generatePDF(
              {
                template: template,
                filters: [filter],
              },
              template.alias,
              uniqueNumbers[0]
            );
          },
        }));

      reportActions = (reportActions || [])
        .concat(moduleReports)
        .concat(documentActions);
    }

    return reportActions;
  };

  render() {
    const {
      metadata,
      columnDefinitions,
      visibleBuckets,
      gridColumns,
      activeViewMode,
      cards,
      gridState,
      tabs,
      activeTab,
      visibleCards,
      lists,
      module,
    } = this.state;

    const { history } = this.props;
    const selected = this.context.detailsPane.card;

    const renderVisualisation = (context) => {
      switch (activeViewMode) {
        case BoardViewType.Grid:
          return (
            <Grid
              statuses={metadata.statuses}
              cards={cards}
              selected={selected}
              setCheckedCards={this.setCheckedCards}
              handleCardToggle={this.handleCardToggle}
              onCheckChange={this.handleCardCheck}
              navigateDetailsPane={this.navigateDetailsPane}
              module={module}
              context={context}
              columnDefinitions={columnDefinitions}
              gridColumns={gridColumns}
              visibilityPredicates={_visibilityPredicates}
              views={this.views}
              gridState={gridState}
              tabs={tabs}
              activeTab={activeTab}
              visibleCards={visibleCards}
            />
          );
        case BoardViewType.Map:
          return (
            <MapView
              onToggle={this.handleCardToggle}
              data={lists}
              selected={selected}
            />
          );
        case BoardViewType.Tree:
          return (
            <Tree
              data={lists}
              selected={selected}
              onToggle={this.handleCardToggle}
              onCheckChange={this.handleCardCheck}
              context={context}
              history={history}
              module={module}
              views={this.views}
              getCardProps={this.getCardProps}
            />
          );
        case BoardViewType.Gantt:
          return (
            <Gantt
              data={lists}
              selected={selected}
              onToggle={this.handleCardToggle}
              onCheckChange={this.handleCardCheck}
              onDrop={this.onDrop}
              canDrop={this.canDrop}
              context={context}
              history={history}
              module={module}
              views={this.views}
            />
          );
        case BoardViewType.KanBan:
        default:
          return (
            <KanBan
              data={lists}
              selected={selected}
              onToggle={this.handleCardToggle}
              onCheckChange={this.handleCardCheck}
              onDrop={this.onDrop}
              canDrop={this.canDrop}
              context={context}
              history={history}
              module={module}
              getCardProps={this.getCardProps}
              visibleBuckets={visibleBuckets}
              visibilityPredicates={_visibilityPredicates}
              views={this.views}
            />
          );
      }
    };

    const renderViewModeSelector = (context) => {
      const requestedViewModes = this.getViewModes();
      const viewModes = requestedViewModes.filter(
        (x) =>
          !context.platform.isMobile ||
          ![BoardViewType.Grid, BoardViewType.Tree].contains(x) ||
          requestedViewModes.length === 1
      );
      const showViewModeSelector = viewModes.length > 1;

      if (!context.platform.isMobile && !showViewModeSelector) {
        return false;
      }

      const iconMap = {
        [BoardViewType.KanBan]: "fab fa-trello",
        [BoardViewType.Grid]: "far fa-table",
        [BoardViewType.Map]: "far fa-globe",
        [BoardViewType.Tree]: "far fa-folder-tree",
        [BoardViewType.Gantt]: "fas fa-project-diagram",
      };

      const buttons = viewModes
        .map((viewMode) => (
          <div
            key={`viewmode-${viewMode}`}
            className={`toggle ${
              this.state.activeViewMode === viewMode ? "on" : "off"
            }`}
            onClick={() => this.setViewMode(viewMode)}
          >
            <i className={iconMap[viewMode]}></i>
          </div>
        ))
        .interleave((viewModeElement) => (
          <Spacer key={`${viewModeElement.key}-spacer`} width="10px" />
        ));

      return (
        <Fragment>
          {!context.platform.isMobile && <Spacer width="30px" />}
          <div className="board-header-toolbar-toggle">{buttons}</div>
        </Fragment>
      );
    };

    const renderFullReportActions = () => {
      const actions =
        this.state.metadata.board.boardClass.getFullExportActions &&
        this.state.metadata.board.boardClass.getFullExportActions();

      const buttons = (actions || []).map((action, i) => (
        <Button
          className="report"
          onClick={action.onClick}
          key={`report_button_${i}`}
        >
          {" "}
          {action.content}{" "}
        </Button>
      ));

      return <Fragment>{buttons}</Fragment>;
    };

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="board-wrapper">
            <div className="board">
              <div className="board-header">
                {!context.platform.isMobile && (
                  <div className="board-header-title">
                    {this.state.title}
                    <Badge className="red">
                      {this.state.cards.filter((x) => !x.isDeleted).length}
                    </Badge>
                    {metadata.settings &&
                      arxs.isActionAllowed(metadata.settings.writeAction) && (
                        <i
                          className="far fa-cog module-settings"
                          onClick={() =>
                            this.props.history.push(metadata.settings.route)
                          }
                        ></i>
                      )}
                    {arxs.Identity.profile.allowedFeatures[Feature.Library_Reading] &&
                      (<i
                        className="far fa-building-columns arxs-library"
                        onClick={() => 
                          context.popup.show(createLibraryPopup(module))
                        }
                        ></i>)}
                    {renderFullReportActions()}
                  </div>
                )}
                <div className="board-header-toolbar">
                  <div className="board-header-toolbar-search">
                    {context.platform.isMobile && (
                      <div className="board-header-toolbar-icon">
                        <i
                          className={arxs.modules.icons[this.state.module]}
                        ></i>
                      </div>
                    )}
                    <div className="input-wrapper">
                      <i className="far fa-search"></i>
                      <input
                        type="text"
                        value={this.state.searchTerm}
                        onChange={this.handleChangeSearchTerm}
                        placeholder={arxs.t("kanban.search_placeholder", {
                          module: this.state.title.toLowerCase(),
                        })}
                      />
                    </div>
                    <Button
                      className="icon"
                      onClick={() => this.handleAdvancedFilter(context)}
                    >
                      <i className="far fa-filter"></i>
                    </Button>
                  </div>
                  <div className="board-header-toolbar-actions">
                    {renderViewModeSelector(context)}
                    {this.renderAddNewButton(context)}
                  </div>
                </div>
              </div>
              {this.renderViewControls()}
              <div className="board-scroller">
                <div className="board-body">{renderVisualisation(context)}</div>
              </div>
              {this.renderOperations(context)}
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
// This allows to use the context inside of the component via this.context
Board.contextType = GlobalContext;
